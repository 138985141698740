/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
:root {
  --accent: #ff4a17;
  // --accent: #5e65bf;
}
$white: #fff;
$black: #161616;
$primary: #fefefe;
$secondary: rgba($primary, 0.7);
$ternary: #999696;
$border: #4d4d4d;
$gray: #111111;
$accent: var(--accent);
// $white: #fff;
// $black: #161616;
// $primary: #fefefe;
// $secondary: rgba($primary, 0.7);
// $ternary: #999696;
// $border: #4d4d4d;
// $gray: #141414;
// $accent: var(--accent);
